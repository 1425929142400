<template>
    <div class="page-layout" v-if="pageData && pageData.attributes">
    
        <HeaderIntroImage underlined :headerMetadata="pageData"></HeaderIntroImage>
        
        <div class="container">

            <div class="page-body blue-title cookie-policy">
                <h3>Why a Cookie Policy?</h3>
                <p>{{ this.name }} has adopted this cookie policy in order to protect the privacy of the visitors to
                    the
                    website {{ this.website }}. The policy ensures that visitors are fully aware of the cookies being
                    stored
                    on their device (PC, tablet, smartphone, notebook) during navigation, and allows them to make an
                    informed
                    choice about their acceptance of cookies.</p>

                <p>This policy has been provided by {{ this.name }} {{ this.indirizzo ? (", with registered office in " + this.indirizzo) : "" }}, as website manager and data controller with respect to the cookies installed directly by
                    this website, pursuant to Sections 13 and 122 of the legislative decree No. 196 of 30 June 2013 (the
                    personal data protection Code, hereinafter the “Code”) and to the decision of the Italian Data
                    Protection
                    Authority No. 229 of 8 May 2014 (Simplified Arrangements to Provide Information and Obtain Consent
                    Regarding
                    Cookies).</p>

                <p>In accordance with the “Cookie Law”, this page describes what visitors need to know about how Polo del
                    Gusto
                    s.r.l. uses cookies and how to manage or remove them, if the users wish to do so; which information the
                    cookies gather; how {{ this.name }} uses them; and why {{ this.name }} may need to store
                    them.
                    {{ this.name }} will also disclose how visitors can prevent these cookies from being stored, which
                    may
                    downgrade or disable certain elements of this website: <a
                        href="https://www.garanteprivacy.it/cookie">www.garanteprivacy.it/cookie</a></p>

                <h3>What are Cookies?</h3>

                <p>Cookies are small text files that are sent to the user's computer or mobile device (usually to the user’s
                    browser) by the visited websites; they are stored in the user’s terminal equipment to be then
                    re-transmitted
                    to the websites on the user’s subsequent visits to those websites.</p>

                <p>They enable the website to remember the user’s actions and preferences (such as login, language, font
                    size
                    and other display preferences), over a period of time, so he doesn’t have to keep re-entering them
                    whenever
                    he comes back to the site or browses from one page to another, for example. Cookies do not cause any
                    damage
                    to the visitor’s device. They are not computer programs and cannot be used to circulate viruses.</p>

                <p>Web pages may contain electronic images known as web beacons - sometimes called “clear GIFs”,
                    “single-pixel
                    GIFs”, or “web bugs”. Web beacons are used to deliver cookies on this website, count
                    clicks/users/visitors,
                    and to deliver co-branded content or services. The website may also contain web beacons from third
                    parties
                    to help the manager of this website to compile aggregated statistics regarding website operations. These
                    web
                    beacons may allow the third parties to set or read cookies on visitors’ device. In addition to the
                    aforementioned cookies and web beacons, the website also uses other technologies to store and retrieve
                    data
                    from visitors device. This may be done to maintain their preferences or to improve speed and performance
                    by
                    storing certain files locally. All of these technologies are referred to as “cookies”.</p>

                <h3>What are Cookies used for?</h3>

                <p>Cookies are present as a rule in substantial numbers in each user's browser and sometimes they remain
                    stored
                    for a long time. They are used for several purposes ranging from IT authentication to the monitoring of
                    browsing sessions up to the storage of specific information on user configurations in accessing a given
                    server, and so on.</p>

                <p>In order to appropriately regulate these devices, it is necessary to distinguish them by having regard to
                    the
                    purposes sought by the entities relying on them, as there are no technical features that allow
                    differentiating them. This is actually the approach followed by the Italian Parliament, which provided
                    for
                    the obligation to obtain the users' prior informed consent to the installation of cookies for purposes
                    other
                    than those of a merely technical nature – according to EC directive 2009/136 (see Section 1(5), letter
                    a),
                    of the legislative decree No. 69 of 28 May 2012, which amended Section 122 of the Code).</p>

                <p>From this point and for the purposes of this decree, cookies may be classified into two major groups:
                    “technical” cookies and “profiling” cookies:</p>

                <p><strong>1.&nbsp;TECHNICAL COOKIES:&nbsp;</strong>these are used exclusively with a view to “carrying out
                    the
                    transmission of a communication on an electronic communications network, or insofar, as this is strictly
                    necessary to the provider of information society service that has been explicitly requested by the
                    contracting party or user to provide the said service” (see Section 122(1) of the Code. They are not
                    used
                    for further purposes and are usually installed directly by the data controller or the website manager.
                    They
                    can be grouped into:</p>

                <p><em><strong>-&nbsp;browsing or session cookies</strong></em>, which allow users to navigate and use a
                    website
                    (e.g. to purchase items online or authenticate themselves to access certain sections);</p>

                <p><em><strong>-&nbsp;analytics cookies</strong></em>, which can be equated to the technical cookies, as
                    they
                    are used directly by the website manager to collect aggregate information on the number of visitors and
                    the
                    pattern of visits to the website;</p>

                <p><em><strong>-&nbsp;functional cookies</strong></em>, which allow users to navigate in a certain
                    pre-determined criteria such as language or products to be purchased in order to improve the quality of
                    service.</p>

                <p>Users' prior consent is not necessary to install these cookies, whilst information under Section 13 of
                    the
                    code has to be provided in a manner that is considered to be most appropriate by the website manager –
                    if
                    only such cookies are relied upon. Besides, taking into consideration the guidance that is provided by
                    the
                    “Article 29 Working Party”, in particular, its Opinion 4/2012 on Cookie Consent Exemption as adopted on
                    7
                    June 2012, the following cookies can be exempted from informed consent under certain conditions and if
                    they
                    are not used for additional purposes:</p>

                <p><strong>a.&nbsp;</strong>user input cookies (session - id), for the duration of a session or persistent
                    cookies limited to a few hours in some cases;</p>

                <p><strong>b.&nbsp;</strong>authentication cookies, that are used for authenticated services, for the
                    duration
                    of a session;</p>

                <p><strong>c.&nbsp;</strong>security cookies focused on users, used to detect the abuse of the
                    authentication,
                    for a limited persistent duration;</p>

                <p><strong>d&nbsp;.</strong>multimedia content player session cookies, such as flash player cookies, for the
                    duration of a session;</p>

                <p><strong>e.&nbsp;</strong>load balancing session cookies, for the duration of session;</p>

                <p><strong>f.&nbsp;</strong>customization persistent cookies, for the duration of a session (or slightly
                    more);
                </p>

                <p><strong>g.&nbsp;</strong>third party social plug-in content sharing cookies, for logged-in members of
                    social
                    networks.</p>

                <p>With regard to the social networks, the working party notes, however, that the use of third party social
                    plug-in cookies, for other purposes than to provide a functionality explicitly requested by their own
                    members, requires consent, notably if these purposes involve tracking users across websites;</p>

                <p><strong>2.&nbsp;PROFILING COOKIES:&nbsp;</strong>are aimed at creating user profiles. They are used to
                    send
                    ads messages in line with the preferences shown by the user during navigation. Because of the highly
                    invasive nature of these cookies vis-à-vis users' private sphere, Italian and European legislation
                    requires
                    users to be informed appropriately of the use of these cookies, and to give their valid consent. These
                    cookies are referred to in Section 122(1) of the Code where it is provided that “storing information, or
                    accessing information that is already stored, in the terminal equipment of a contracting party or user
                    shall
                    only be permitted on condition that the contracting party or user has given his consent after being
                    informed, in accordance with the simplified arrangements mentioned in Section 13(3)”.</p>

                <h3>Characteristics of a Cookie</h3>

                <p>Cookies are often categorized according to the following characteristics: whether they are
                    <strong>“session
                        cookies”</strong> or <strong>“persistent cookies”</strong>; whether they are <strong>“third party
                        cookies”</strong> or not.</p>

                <p>A <strong>“session cookie”</strong> is a cookie that is automatically deleted when the user closes his
                    browser, while a <strong>“persistent cookie”</strong> is a cookie that remains stored in the user’s
                    terminal
                    device until it reaches a defined expiration date (which can be minutes, days or several years in the
                    future).</p>

                <p>The term “third party cookie” can be misleading:</p>

                <p>In the context of European data protection, the Directive 95/46/EC defines a third party as “any natural
                    or
                    legal person, public authority, agency or anyone else other than the data subject, the controller, the
                    processor and the persons who, under the direct authority of the controller or the processor, are
                    authorized
                    to process the data. Thus, a “third party cookie”, would refer to a cookie set by a data controller that
                    is
                    distinct from the one that operates the website visited by the user (as defined by the current URL
                    displayed
                    in the address bar of the browser). However, from the perspective of browsers, the notion of “third
                    party”
                    is solely defined by looking at the structure of the URL displayed in the address bar of the browser. In
                    this case “third party cookies” are cookies that are set by websites that belong to a domain that is
                    distinct from the domain of the website visited by the user as displayed in the browser address bar,
                    regardless of any consideration whether that entity is a distinct data controller or not.</p>

                <h3>Why does this website use Cookies?</h3>

                <p>This website uses cookies for a variety of reasons as mentioned below.</p>

                <p>Some cookies are essential, it means that they are necessary to navigate the website and use its services
                    and
                    features. Enabling these cookies is not strictly necessary for the website to work, but it will provide
                    the
                    users a better browsing experience. Users can delete or block these cookies, but if they do that some
                    features of this site may not work as intended. Other cookies are non-essential; they may record users’
                    preferences and enable the website to perform as they would like, or enable us to perform analytics or
                    serve
                    advertisements. The main reasons for the cookies’ presence on the site, are to:</p>

                <p><em><strong>-&nbsp;remember choice (acceptance cookie)</strong></em>: this cookie is used to record when
                    a
                    user has given their implied consent for their use;</p>

                <p><em><strong>-&nbsp;keep track of the user’s input (user’s input cookies)</strong></em>: this typical
                    first
                    party user’s input session cookie is used to keep track of the user’s input in a series of message
                    exchanges
                    with a service provider in a consistent manner: as when filling online forms over several pages or
                    providing
                    an information service explicitly requested by the user;</p>

                <p><em><strong>-&nbsp;remember the user (authentication cookie)</strong></em>: this session cookie is used
                    to
                    identify the user once he logs in, so he can browse multiple pages and do things like downloading
                    content
                    without being asked for his log in details every time. This cookie is also needed to allow users to
                    authenticate on successive visits to the website and gain access to the authorized content. This cookie
                    is
                    usually deleted when the user logs out. However, in some cases they may remain longer in order to
                    remember
                    their site preferences when logged out;</p>

                <p><em><strong>-&nbsp;store a user’s preference (user interface customization cookie)</strong></em>: this
                    cookie
                    is used regarding a service across web pages and not linked to other persistent identifiers such as the
                    username. It is only set if the user has explicitly requested the service to remember a certain piece of
                    information, for example, by clicking on a button or ticking a box. It can be a session cookie or have a
                    lifespan counted in weeks or months, depending on their purpose. Typical examples of customization
                    cookies
                    are: language preference cookies that are used to remember the language selected by a user on a
                    multilingual
                    website (e.g. by clicking on a “flag”), result display preference cookies that are used to remember the
                    user’s preference regarding online search queries (e.g. by selecting the number of results per page);
                </p>

                <p><em><strong>-&nbsp;enable social sharing (social plug-in content sharing cookie)</strong></em>: many
                    social
                    networks propose “social plug-in modules” that website operators can integrate in their platform notably
                    to
                    allow social networks users to share contents they like with their “friends” (and other related
                    functionalities proposes such as publishing comments). These plug-ins store and access cookies in the
                    user’s
                    terminal equipment in order to allow the social network to identify their members when they interact
                    with
                    these plug-ins. It is important to distinguish users who “logged-in” through their browser in a
                    particular
                    social network account, from “non-logged-in” users who are either simply not a member of that specific
                    social network or who have “disconnected” from their social network account. Since by definition social
                    plug-ins are destined to members of a particular social network, they are not of any use for non
                    members,
                    and therefore consent from non-members and “logged-out” members is needed before third party cookies can
                    be
                    used by social plug-ins;</p>

                <p><em><strong>-&nbsp;provide data for improving the websites (analytics cookie)</strong></em>: analytics
                    are
                    statistical audience measuring tools for websites, which often rely on cookies. These tools are notably
                    used
                    by website owners to estimate the number of unique visitors, to detect the most preeminent search engine
                    keywords that lead to a webpage or to track down website navigation issues. Analytics tools use a number
                    of
                    different data collection and analysis models, however the first party analytics cookies are not likely
                    to
                    create a privacy risk when they are strictly limited to first party aggregated statistical purposes and
                    when
                    they are used by websites that already provide clear information about these cookies in their privacy
                    policy
                    as well as adequate privacy safeguards. Such safeguards are expected to include a user friendly
                    mechanism to
                    opt-out from any data collection and comprehensive anonymization mechanisms that are applied to other
                    collected identifiable information such as IP addresses;</p>

                <h3>Which Cookies are used in this website?</h3>

                <p>This site does not use profiling cookies to send advertising messages in line with the user's online
                    navigation preferences (behavioral advertising).</p>

                <h3>1. First-party cookies</h3>

                <p>Pursuant to Italian “Cookie Law”, the website manager describes in the table below the detailed features
                    and
                    purposes of the cookies installed by this website, including their duration and the consequences of
                    their
                    disabling.</p>

                <br><br>

                <table border="1" cellpadding="0" cellspacing="0" class="desktop-table">
                    <thead>
                        <tr>
                            <th>
                                <p><strong>Cookie Name</strong></p>
                            </th>
                            <th>
                                <p><strong>Cookie Type</strong></p>
                            </th>
                            <th>
                                <p><strong>Purpose</strong></p>
                            </th>
                            <th>
                                <p><strong>Duration</strong></p>
                            </th>
                            <th>
                                <p><strong>Consequences of disabling</strong></p>
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>
                                <p><strong>SESSxxxx</strong></p>
                            </td>
                            <td>
                                <p>Technical cookie</p>
                            </td>
                            <td>
                                <p>Session cookie for logged in users.</p>
                            </td>
                            <td>
                                <p>23 days</p>
                            </td>
                            <td>
                                <p>Cannot log in.</p>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <p><strong>dhtml_menu</strong></p>
                            </td>
                            <td>
                                <p>Technical cookie</p>
                            </td>
                            <td>
                                <p>To store user interface preferences.</p>
                            </td>
                            <td>
                                <p>Session</p>
                            </td>
                            <td>
                                <p>Some interface preferences will not be available.</p>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <p><strong>has_js</strong></p>
                            </td>
                            <td>
                                <p>Technical cookie</p>
                            </td>
                            <td>
                                <p>To record whether your browser has JavaScript enabled.</p>
                            </td>
                            <td>
                                <p>Session</p>
                            </td>
                            <td>
                                <p>Many parts of the website will not be available.</p>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <p><strong>consent</strong></p>
                            </td>
                            <td>
                                <p>Technical cookie</p>
                            </td>
                            <td>
                                <p>Records acceptance of Cookie Policy.</p>
                            </td>
                            <td>
                                <p>1 year</p>
                            </td>
                            <td>
                                <p>Disabling will cause the Cookie banner to be displayed on every new page load.</p>
                            </td>
                        </tr>
                    </tbody>
                </table>

                <div class="mobile-table">
                    <ul>
                        <li><span class="lable">Cookie Name: </span> <span class="value"> SESSxxxx </span></li>
                        <li><span class="lable">Cookie Type: </span> <span class="value"> Technical cookie </span></li>
                        <li><span class="lable">Purpose: </span> <span class="value"> Session cookie for logged in users.
                            </span></li>
                        <li><span class="lable">Duration: </span> <span class="value"> 23 days </span></li>
                        <li><span class="lable">Consequences of disabling: </span> <span class="value"> Cannot log in.
                            </span>
                        </li>
                    </ul>

                    <ul>
                        <li><span class="lable">Cookie Name: </span> <span class="value"> dhtml_menu </span></li>
                        <li><span class="lable">Cookie Type: </span> <span class="value"> Technical cookie </span></li>
                        <li><span class="lable">Purpose: </span> <span class="value"> To store user interface preferences.
                            </span></li>
                        <li><span class="lable">Duration: </span> <span class="value"> Session </span></li>
                        <li><span class="lable">Consequences of disabling: </span> <span class="value"> Some interface
                                preferences will not be available. </span></li>
                    </ul>

                    <ul>
                        <li><span class="lable">Cookie Name: </span> <span class="value"> has_js </span></li>
                        <li><span class="lable">Cookie Type: </span> <span class="value"> Technical cookie </span></li>
                        <li><span class="lable">Purpose: </span> <span class="value"> To record whether your browser has
                                JavaScript enabled. </span></li>
                        <li><span class="lable">Duration: </span> <span class="value"> Session </span></li>
                        <li><span class="lable">Consequences of disabling: </span> <span class="value"> Many parts of the
                                website will not be available. </span></li>
                    </ul>

                    <ul>
                        <li><span class="lable">Cookie Name: </span> <span class="value"> consent </span></li>
                        <li><span class="lable">Cookie Type: </span> <span class="value"> Technical cookie </span></li>
                        <li><span class="lable">Purpose: </span> <span class="value"> Records acceptance of Cookie Policy.
                            </span></li>
                        <li><span class="lable">Duration: </span> <span class="value"> 1 year</span></li>
                        <li><span class="lable">Consequences of disabling: </span> <span class="value"> Disabling will cause
                                the
                                Cookie banner to be displayed on every new page load. </span></li>
                    </ul>
                </div>

                <h3>2. Third-party cookies</h3>

                <p>Pursuant to Italian “Cookie Law”, this policy also contains an updated link to the information notices
                    and
                    consent forms of the third-parties who install cookies via this website. Third parties’ cookies,
                    especially
                    analytics cookies, collect aggregate statistical data about how visitors use the site so that the
                    website
                    manager can improve the user experience and serve up the content that the visitors find most useful.
                    These
                    cookies store information about what pages users visit, how long they are on the website, how they got
                    there
                    and what they click on. They do not collect or store personal data and are not used for any purpose
                    other
                    than those described here.</p>

                <p>This site also uses social media buttons and/or plug-ins that allow users to connect with their social
                    network in various ways. In order to work, social media sites (such as Facebook, Twitter, e.g.) cookies
                    will
                    be set through this site which can be used to enhance their profile on their site or contribute to the
                    data
                    they hold for various purposes outlined in their respective privacy policies. A list of the third
                    parties’
                    cookies used across this site can be seen in the table below:</p>

                <br><br>

                <table border="1" cellpadding="0" cellspacing="0" class="desktop-table" style="width: 600px;">
                    <thead>
                        <tr>
                            <th>
                                <p align="center"><strong>Cookie Name</strong></p>
                            </th>
                            <th>
                                <p align="center"><strong>Cookie Type</strong></p>
                            </th>
                            <th>
                                <p align="center"><strong>Purpose</strong></p>
                            </th>
                            <th>
                                <p align="center"><strong>Link</strong></p>
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>
                                <p align="center"><strong>__utma,<br />
                                        __utmb,<br />
                                        __utmc,<br />
                                        __utmz</strong></p>
                            </td>
                            <td>
                                <p>Technical cookies</p>
                            </td>
                            <td>
                                <p>Google Analytics is one of the most widespread and trusted analytics solutions on the web
                                    for
                                    helping the website manager to understand how users use the site and how he can improve
                                    their experience. These cookies may track things such as how long users spend on the
                                    site
                                    and the pages that they visit so that we can continue to produce engaging content. For
                                    more
                                    information on Google Analytics cookies, see the official Google Analytics page.</p>
                            </td>
                            <td>
                                <p><a href="http://www.google.com/intl/en/analytics/privacyoverview.html"
                                        target="_blank">Google
                                        Analytics</a></p>

                                <br><br>
                            </td>
                        </tr>
                    </tbody>
                </table>

                <div class="mobile-table">
                    <ul>
                        <li><span class="lable">Cookie Name: </span> <span class="value"> __utma,__utmb,__utmc,__utmz
                            </span>
                        </li>
                        <li><span class="lable">Cookie Type: </span> <span class="value"> Technical cookies </span></li>
                        <li><span class="lable">Purpose: </span> <span class="value"> Google Analytics is one of the most
                                widespread and trusted analytics solutions on the web for helping the website manager to
                                understand how users use the site and how he can improve their experience. These cookies may
                                track things such as how long users spend on the site and the pages that they visit so that
                                we
                                can continue to produce engaging content. For more information on Google Analytics cookies,
                                see
                                the official Google Analytics page. </span></li>
                        <li><span class="lable">Link: </span> <span class="value"> <a
                                    href="http://www.google.com/intl/en/analytics/privacyoverview.html"
                                    target="_blank">Google
                                    Analytics</a> </span></li>
                    </ul>
                </div>

                <br><br>

                <h3>How to manage Cookies?</h3>

                <p>Users can control and/or delete cookies as they wish – for details, see aboutcookies.org, but it should
                    be
                    noted that disabling cookies will affect the functionality of this and many other websites that they
                    visit.
                </p>

                <p>They can delete all cookies that are already on their computer and they can set most browsers to prevent
                    them
                    from being placed. However, if they do this, they may have to manually adjust some preferences every
                    time
                    they visit a site and some services and functionalities may not work.</p>

                <p>Unfortunately, in most cases there are no industry standard options for disabling cookies without
                    disabling
                    completely the functionality and features they add to this site. How to disable and to delete cookies?
                </p>

                <p>Users can prevent placing cookies by adjusting the settings on their browser (see browser Help for how to
                    do
                    this), but they should be aware that by changing any of the settings they are likely to change their
                    Internet navigation experience and conditions for accessing certain services requiring the use of
                    cookies.
                </p>

                <p>If they do not allow cookies to be stored on their device or delete any cookie already stored there, they
                    will no longer have access to a certain number of functions that they might need for navigating certain
                    areas of the website. Visitors can decide to confirm or change their options at any time by configuring
                    their navigation software so that cookies are either stored or blocked on their device. Certain browsers
                    allow users to set the rules for managing cookies for individual sites.</p>

                <p>Individual browsers are configured differently for managing cookies. This is described in the browser’s
                    help
                    menu, which will tell the user how to change the options:</p>

                <p><strong>Google Chrome</strong>: <a
                        href="https://support.google.com/chrome/answer/95647?hl=en&amp;hlrm=en">https://support.google.com/chrome/answer/95647?hl=en&amp;hlrm=en</a>
                </p>

                <p><strong>Internet Explorer</strong>: <a
                        href="http://windows.microsoft.com/en-us/windows7/block-enable-or-allow-cookies">http://windows.microsoft.com/en-us/windows7/block-enable-or-allow-cookies</a>
                </p>

                <p><strong>Mozilla Firefox</strong>: <a
                        href="https://support.mozilla.org/en-US/kb/enable-and-disable-cookies-website-preferences">https://support.mozilla.org/en-US/kb/enable-and-disable-cookies-website-preferences</a>
                </p>

                <p><strong>Opera</strong>: <a
                        href="http://help.opera.com/Windows/10.20/en/cookies.html">http://help.opera.com/Windows/10.20/en/cookies.html</a>
                </p>

                <p><strong>Safari</strong>: <a
                        href="https://support.apple.com/en-us/HT201265">https://support.apple.com/en-us/HT201265</a></p>

                <p>Cookies can also be deleted:</p>

                <p><strong>Google Chrome</strong>: <a
                        href="https://support.google.com/accounts/answer/32050?hl=en">https://support.google.com/accounts/answer/32050?hl=en</a>
                </p>

                <p><strong>Internet Explorer</strong>: <a
                        href="https://support.microsoft.com/en-us/kb/278835">https://support.microsoft.com/en-us/kb/278835</a>
                </p>

                <p><strong>Mozilla Firefox</strong>: <a
                        href="https://support.mozilla.org/en-US/kb/delete-cookies-remove-info-websites-stored">https://support.mozilla.org/en-US/kb/delete-cookies-remove-info-websites-stored</a>
                </p>

                <p><strong>Opera</strong>: <a
                        href="http://www.opera.com/help/tutorials/security/privacy">http://www.opera.com/help/tutorials/security/privacy</a>
                </p>

                <p><strong>Safari</strong>: <a
                        href="https://support.apple.com/en-gb/HT201265">https://support.apple.com/en-gb/HT201265</a></p>

                <h3>How are Cookies used by the manager of this website?</h3>

                <p>The manager of this website uses cookies in full respect of Section 122 of the Code and of decision No.
                    229
                    of 8 May 2014, which establishes the lawfulness of these technologies for certain legitimate purposes
                    related to technical storage for the strictly necessary time for the transmission of communication or to
                    provide a specific service requested by the user, who can always refuse the processing of personal data
                    related to storage cookies, by disabling one or more.</p>

                <h3>Hiw are personal data related to Cookies processed?</h3>

                <p>Personal data collected in connection to the use of cookies will be processed using IT and computer
                    systems
                    and will be shared with individuals involved in the business organization of this website or external
                    third
                    parties (technical service suppliers, hosting providers, etc.). The data will be handled through
                    instruments
                    and with modalities that ensure data privacy and security, in accordance with the provisions of the
                    Code.
                </p>

                <h3>Are personal data communicated or disseminated?</h3>

                <p>Personal data related to the use of cookies are not communicated or disseminated: they will be brought to
                    the
                    attention of the processing officers appointed by the controller data and third parties (such as
                    companies
                    providing computer support and ensuring the proper functioning of the site), committed to achieve the
                    purposes described in the tables before reported. In any case, the processing by third parties shall be
                    fairly done and in compliance with the laws in force.</p>

                <h3>What are the rights that the visitor has as a data subject?</h3>

                <p>Pursuant to Sections 7, 8, 9 and 10 of the Code, the visitor, as a data subject, shall have the right to
                    obtain confirmation whether his personal data exist or not, regardless if they are already recorded, and
                    to
                    have communication about such data in an intelligible form. He shall have the right to be informed; a)
                    of
                    the source of the personal data; b) of the purposes and methods of the processing; c) of the logic
                    applied
                    to the processing, if the latter is carried out with the help of electronic means; d) of the
                    identification
                    data concerning data controller, data processors and the representative designated as per Section 5(2);
                    e)
                    of the entities or categories of entity to whom or which the personal data may be communicated and who
                    or
                    which may get to know the said data in their capacity as designated representative(s) in the State’s
                    territory, data processor(s) or person(s) in charge of the processing. He will have also the right to
                    obtain; a) updating, rectification or, where interested therein, integration of the data; b) erasure,
                    anonymization or blocking of data that have been processed unlawfully, including data whose retention is
                    unnecessary for the purposes for which they have been collected or subsequently processed; c)
                    confirmation
                    that the operations as per letters a) and b) have been notified, as also related to their contents, to
                    the
                    entities to whom or which the data were communicated or disseminated, unless this requirement proves
                    impossible or involves a manifestly disproportionate effort compared with the right that is to be
                    protected.
                    A data subject shall have the right to object, in whole or in part, a) on legitimate grounds, to the
                    processing of personal data concerning him/her, even though they are relevant to the purpose of the
                    collection; b) to the processing of personal data concerning him/her, where it is carried out for the
                    purpose of sending advertising materials or direct selling or else for the performance of market or
                    commercial communication surveys.</p>

                <h3>Who is the data controller?</h3>

                <p>The manager of this website uses cookies in full respect of Section 122 of the Code and of decision No.
                    229
                    of 8 May 2014, which establishes the lawfulness of these technologies for certain legitimate purposes
                    related to technical storage for the strictly necessary time for the transmission of communication or to
                    provide a specific service requested by the user, who can always refuse the processing of personal data
                    related to storage cookies, by disabling one or more.</p>

                <h3>How are Cookies used by the manager of this website?</h3>

                <p>The data controller is {{ this.name }}, with the registered office in Via Flavia 112 - 34147
                    Trieste,
                    Italy.</p>

                <h3>Amendments</h3>

                <p>The website manager may change this Cookie Policy at any time. Visitors are invited to take a look at the
                    last updated date at the bottom of this page to see when this Cookie Policy was last revised. Any
                    changes in
                    this Cookie Policy will become effective when the website manager makes the revised Cookie Policy
                    available
                    on or through the website.</p>

                <h3>More information</h3>

                <p>Hopefully we have clarified things for the users. As was previously mentioned, if there is something that
                    the
                    users are not sure whether they need or not, it's usually safer to leave cookies enabled in case they do
                    interact with one of the features that the users use on our site. However if the users are still looking
                    for
                    more information, they can contact the website manager through e-mail: <a
                        :href="'mailto:'+this.email">{{ this.email }}</a></p>

                <p><em>Updated on: {{ this.updateDate }}</em></p>
            </div>
            <!-- END SNIPPET -->
        </div>
    </div>

</template>

<script>
import HeaderIntroImage from '../components/layout-blocks/HeaderIntroImage.vue'
export default {
    name: 'cookie-policy',
    components: {
        HeaderIntroImage
    },
    data() {
        return {
            pageData: {
                attributes: {
                    title: "Cookie Policy"
                }
            },
            currentPageID: "",
            name: "QTEdu",
            email: "", 
            website: "https://www.qtedu.org",
            indirizzo: "",
            updateDate: "Jan 26, 2022"
        }
    }
}
</script>

<style lang="scss" scoped>


h4 { margin-top: 2rem; }
h3 { margin-top: 3rem; }
h2 { margin-top: 4rem; }

.privacy-policy table,
.cookie-policy table {
    width: 100% !important;
    border-left: 0px solid #666;
    border-bottom: 0px solid #666;
}

.privacy-policy table p,
.cookie-policy table p {
    padding: 10px;
}

.privacy-policy table th,
.cookie-policy table th,
.privacy-policy table td,
.cookie-policy table td {
    border: 0px solid #666;
}

.privacy-policy table th,
.cookie-policy table th {
    border-left: 1px solid #666;
    border-bottom: 1px solid #666;
}

.privacy-policy table td,
.cookie-policy table td {
    border-left: 1px solid #666;
    border-bottom: 1px solid #666;
}

/* .privacy-policy li,
.cookie-policy li,
.privacy-policy p,
.cookie-policy p {
    font-size: 0.875rem;
} */

.privacy-policy li strong,
.cookie-policy li strong,
.privacy-policy p strong,
.cookie-policy p strong {
    font-weight: 600;
    margin-top: 2rem;
}

.privacy-policy p+p,
.cookie-policy p+p {
    margin-top: 1rem;
}

.privacy-policy ul,
.cookie-policy ul {
    padding-left: 30px;
    margin-bottom: 20px;
}

.privacy-policy ul li,
.cookie-policy ul li {
    padding: 5px 0px;
}


.mobile-table {
    display: none;
}

.mobile-table ul {
    padding-left: 0px !important;
    padding: 0;
    margin-bottom: 2rem !important;
}

.mobile-table li {
    padding: 8px 0px;
    list-style: none;
}

.mobile-table span {
    display: block;
    font-size: 1rem;
}

.mobile-table .lable {
    font-size: 0.875rem;
    font-weight: 600;
}

@media (max-width:1024px) {
    .desktop-table {
        display: none;
    }

    .mobile-table {
        display: block;
    }
}
</style>